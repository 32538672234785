import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import {
  Button,
  HeaderNav,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor,
  AUTHENTICATE_ROUTE,
  FUND_MY_PROJECT,
  PROJECT_ROUTE,
} from 'kiss/routes/redux'

const UserLoggedOut = ({ bonusComponent }) => {
  const routeFor = useSelector(getRouteFor)
  const t = useTranslation()
  const { pathname } = useLocation()
  const isPathMatching = (path) =>
    matchPath(pathname, {
      path: routeFor(path),
      exact: true,
    })

  // TODO add history.push() in OnClick function when 404 page will be in react
  return (
    <HeaderNav.LoggedOut>
      {bonusComponent || null}

      <HeaderNav.Button
        backgroundColor="var(--color-grey-000)"
        backgroundColorHover="var(--color-grey-000)"
        color="var(--color-grey-900)"
        colorHover="var(--color-primary-500)"
        text={t('layouts.header.authenticate')}
        href={routeFor(AUTHENTICATE_ROUTE)}
        className="k-u-hidden@m-down"
        data-test-id="user-login-button"
      />

      {!isPathMatching(PROJECT_ROUTE) && (
        <Button
          as="a"
          size="small"
          modifier="helium"
          rounded
          href={routeFor(FUND_MY_PROJECT)}
          data-test-id="header-start-your-project"
          className="k-u-hidden@m-down"
        >
          {t('layouts.header.start_your_project')}
        </Button>
      )}
    </HeaderNav.LoggedOut>
  )
}

export default UserLoggedOut
