import axios from 'axios'

export const createToken = async ({
  email,
  password,
  redirect_to,
  client_id,
}) => {
  return axios.post(
    '/auth',
    {
      user: { email, password },
      redirect_to,
      client_id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}
