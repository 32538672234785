import { createSelector } from '@reduxjs/toolkit'
import engagementsData from 'kiss/data/engagements.json'
import { RoutingHelper } from 'kiss/utils/routing-helper'

// HomePage.
export const HOMEPAGE = '/:locale'
export const HOMEPAGE_ROUTE = '/:locale(fr|en|nl)?'

// Manifesto
export const MANIFESTO = '/:locale/manifesto'

// Messaging.
export const MESSAGING = '/:locale/messaging'
export const MESSAGING_ROOM = '/:locale/messaging/:room'

// Project.
export const PROJECT_ROUTE = '/:locale/projects/:project/'
export const SUBSCRIBER_PROJECT_NEWS_ROUTE = '/:locale/projects/:project/news'
export const SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE =
  '/:locale/projects/:project/subscription'
export const SUBSCRIBER_PROJECT_FAQ_ROUTE = '/:locale/projects/:project/faq'
export const SUBSCRIBER_PROJECT_ABOUT_ROUTE = '/:locale/projects/:project/about'
export const PROJECT_ROUTE_SUBMISSION_SUCCEED = `${PROJECT_ROUTE}?submission_status=succeed`
export const PROJECT_PREVIEW = '/:locale/projects/:project/preview'
export const PROJECT_NAVIGATION_ROUTE =
  '/:locale/projects/:project/:tabs?/:item?'
export const PROJECT_NAVIGATION_TAB_ROUTE =
  '/:locale/projects/:project/tabs/:item'
export const PROJECT_NAVIGATION_DESCRIPTION_TAB_ROUTE =
  '/:locale/projects/:project/tabs/description'
export const PROJECT_NAVIGATION_NEWS_TAB_ROUTE =
  '/:locale/projects/:project/tabs/news'
export const PROJECT_NAVIGATION_COMMENTS_TAB_ROUTE =
  '/:locale/projects/:project/tabs/comments'
export const PROJECT_NAVIGATION_BACKERS_TAB_ROUTE =
  '/:locale/projects/:project/tabs/backers'
export const PROJECT_NAVIGATION_FAQS_TAB_ROUTE =
  '/:locale/projects/:project/tabs/faqs'
export const PROJECT_NAVIGATION_ABOUT_ROUTE =
  '/:locale/projects/:project/tabs/about'
export const PROJECT_UNSUBSCRIBE_NEWS =
  '/:locale/projects/:project/unsubscribe-news'
export const PROJECT_SHARED_NEWS = '/:locale/projects/:project/news/:id'

//TODO rename after testing
export const PROJECT_WIDGET = '/:locale/projects/:project/widget_v2'
export const PROJECT_DONATION_RECEIPT =
  '/:locale/projects/:project/donation_receipt.pdf'

// Password
export const RESET_PASSWORD = '/:locale/users/password/edit'

// Project manager
export const VERCEL_PROJECT_MANAGER = '/:locale/project/:project'
export const VERCEL_PROJECT_MANAGER_NEWS = '/:locale/project/:project/news'
export const VERCEL_PROJECT_MANAGER_NEWS_CREATE =
  '/:locale/project/:project/news/create'
export const VERCEL_PROJECT_MANAGER_PAGE = '/:locale/project/:project/page'

// Organization manager
export const VERCEL_ORGANIZATION_MANAGER_ROOT =
  '/:locale/:organization/manage/settings'
export const VERCEL_ORGANIZATION_MANAGER = '/:locale/:organization/:project'
export const VERCEL_ORGANIZATION_MANAGER_NEWS =
  '/:locale/:organization/:project/news'
export const VERCEL_ORGANIZATION_MANAGER_NEWS_CREATE =
  '/:locale/:organization/:project/news/create'
export const VERCEL_ORGANIZATION_MANAGER_PAGE =
  '/:locale/:organization/:project/page'

// Funnel
export const FUNNEL = '/:locale/funnel/create/login'
export const FUNNEL_WITH_CHALLENGE = '/:locale/funnel/create/login/:challenge'
export const FUNNEL_STEPS = '/:locale/funnel/:project/create/:step'

export const MENTOR_PAGE = '/:locale/users/:mentor'
export const MENTOR_PAGE_PROJECTS_TAB = '/:locale/users/:mentor/projects'
export const MENTOR_PAGE_EDITION = '/:locale/users/:mentor/edit'
export const MENTOR_SIGNUP = '/:locale/users/:mentor/signup'

// Browsing.
export const BROWSING = '/:locale/discover'
export const BROWSING_BY_ENGAGEMENT = `/:locale/discover/:engagement(${Object.keys(
  engagementsData,
).join('|')})`
export const ENGAGEMENT_BROWSING = `/:locale/discover/:engagement`
export const BROWSING_POPULAR = '/:locale/discover?filter=popular'
export const BROWSING_POPULAR_AND_STARTED =
  '/:locale/discover?scope=popular&state=started'

// Search.
export const SEARCH = '/:locale/search'

// Fund My Project.
const MAKER_BARE_PROJECT = '/:locale/financer-mon-projet'
export const FUND_MY_PROJECT = '/:locale/financer-mon-projet'
export const FINANCING_METHOD_SECTION = 'financing-methods'
export const SUPPORT_SERVICES_SECTION = 'support-services'
export const FUND_MY_PROJECT_WITH_CHALLENGE =
  '/:locale/financer-mon-projet/:challenge'
export const FUND_MY_PROJECT_SUBPAGE = '/:locale/financement/:template_name'
const FUND_MY_PROJECT_DONATION = '/:locale/financement/don'
const FUND_MY_PROJECT_PRESALE = '/:locale/financement/prevente'
const FUND_MY_PROJECT_REWARD = '/:locale/financement/don-contrepartie'
const FUND_MY_PROJECT_PERMANENT = '/:locale/financement/don-recurrent'
export const FUND_MY_PROJECT_CAMPAIGN = '/:locale/financement/cagnotte'
export const FUND_MY_PROJECT_PRE_ORDER = '/:locale/financement/precommande'
export const FUND_MY_PROJECT_SUBSCRIPTION = '/:locale/financement/abonnement'
export const CAMPAIGN_TIPS_SUBPAGE =
  '/:locale/reussir-sa-collecte/:template_name'
export const CAMPAIGN_TIPS_PREPARE_YOUR_COLLECT =
  '/:locale/reussir-sa-collecte/preparer-sa-collecte'
const CAMPAIGN_TIPS_COMMUNICATION = '/:locale/reussir-sa-collecte/communication'
const CAMPAIGN_TIPS_SEND_REWARDS =
  '/:locale/reussir-sa-collecte/envoi-contrepartie'

// Preview
export const PRISMIC_PREVIEW = '/prismic/preview'

// Pricing plans
export const PRICING_PLANS = '/:locale/pricing'

// Organization
export const ORGANIZATION_PROFILE_PUBLIC = '/:locale/organizations/:slug'

// Users
export const USER = '/:locale/users/:user'
export const USER_PROFILE = '/:locale/users/:user/profile'
export const USER_PUBLIC_PROFILE = '/:locale/users/:user/profile/public'
export const USER_PROFILE_PROJECTS = '/:locale/users/:user/profile/projects'
export const USER_PROFILE_FAVORITES = '/:locale/users/:user/profile/favorites'
export const USER_PROFILE_NOTIFICATIONS =
  '/:locale/users/:user/profile/notifications'
export const USER_PROFILE_CONTRIBUTIONS =
  '/:locale/users/:user/profile/contributions'
export const STOP_MASQUERADE_USER = '/:locale/masquerades/stop'
export const START_MASQUERADE_USER =
  '/:locale/masquerades/start?project_id=:project&user_id=:user'
export const USER_MESSAGE_NEW =
  '/:locale/mailboxer/messages/new?recipient_id=:user'
export const USER_KYC = '/:locale/users/:user/kyc'
export const USER_SUBSCRIPTION_UPDATE_CARD_POLLING =
  '/:locale/contributor/subscriptions/:subscription/update-card-polling'

//Contribute
export const CONTRIBUTE = '/:locale/projects/:project/contribute'
export const CART = '/:locale/projects/:project/contribute'
export const IDENTITY_CHECK =
  '/:locale/projects/:project/contribute/identity-check'
export const PAYMENT_CHOICES =
  '/:locale/projects/:project/contribute/payment-choices'
export const PAYMENT_CHOICES_RETURN =
  '/:locale/projects/:project/contribute/payment-choices/:orderId'
export const SHIPPING_ADDRESS =
  '/:locale/projects/:project/contribute/shipping-address'
export const BILLING_ADDRESS = '/:locale/projects/:project/contribute/billing'
export const THANK_YOU = '/:locale/projects/:project/contribute/thank-you'
export const PAYMENT_FAILURE = '/:locale/projects/:project/contribute/failure'
export const CARD_PAYMENT_FAILURE =
  '/:locale/projects/:project/contribute/card-failure'
export const CONTRIBUTE_LOGIN = '/:locale/projects/:project/contribute/login'
export const CONTRIBUTE_SIGN_IN =
  '/:locale/projects/:project/contribute/sign-in'
export const CONTRIBUTE_SIGN_UP =
  '/:locale/projects/:project/contribute/sign-up'
// Authenticate.
export const AUTHENTICATE_ROUTE = '/:locale/users/authenticate'
export const SIGN_UP = '/:locale/users/sign_up'
export const SIGN_IN = '/:locale/users/sign_in'
export const LOGOUT = '/:locale/logout'

//KissConnect
export const KISS_CONNECT_SIGN_IN = '/:locale/auth'
export const KISS_CONNECT_VALIDATE = '/auth/validate'

// Pages.
export const HELP = 'https://aide.kisskissbankbank.com/kb/:locale'
export const COMPLAINTS =
  'https://aide.kisskissbankbank.com/kb/guide/fr/existe-t-il-une-solution-de-mediation-mJYEt7Kpoz/Steps/339324'
export const MENTORS = '/:locale/mentors'
export const STATS = '/:locale/stats'
export const LE_STUDIO = 'https://studio.kisskissbankbank.com/'
export const FONCTIONNALITES = '/:locale/crowdfunding/fonctionnalites'
export const RESTRICTED = '/:locale/restricted'
export const PROJECT_DELETED = '/:locale/project-deleted'

// From Prismic to code.
export const SERVICES = '/:locale/crowdfunding/services'

// Gift card.
export const GIFT_CARD = '/:locale/carte-cadeau'
export const GIFT_CARD_NEW = '/:locale/carte-cadeau/new'
export const GIFT_CARD_SHOW = '/:locale/carte-cadeau/:id'

// Static pages.
export const PAGES_ROUTE =
  '/:locale/pages/:page(crowdfunding|terms|legal|values|press|privacy|accessibilite|criteres-selection|conditions-generales)'
export const PAGES_WITH_FOLDER_ROUTE = '/:locale/pages/:folder/:page'

export const TEAM_MINIPAGE = 'https://team.kisskissbankbank.com/'
export const CROWDFUNDING = '/:locale/pages/crowdfunding'
export const CRITERE_SELECTIONS = '/:locale/pages/criteres-selection'
export const PRESS = '/:locale/pages/press'
export const CGU = '/fr/pages/conditions-generales'
export const LEGAL = '/fr/pages/legal'
export const PRIVACY = '/:locale/pages/privacy'
export const VALUES = '/:locale/pages/values'
export const COOKIES = '/:locale/cookies'
export const PAGE_LA_BANQUE_POSTALE = '/:locale/crowdfunding/la-banque-postale'
export const LOCAL_AUTHORITIES =
  '/:locale/crowdfunding/collectivites-territoriales'

// Landing pages.
export const LANDINGS_ROUTE = '/:locale/crowdfunding/:page'
export const LANDINGS_WITH_FOLDER_ROUTE = '/:locale/crowdfunding/:folder/:page'
export const PARTNERS = '/:locale/crowdfunding/partenariat'
export const BEST_CAMPAIGNS =
  '/:locale/crowdfunding/record-campagne-financement-participatif'

// Conversations.
export const UNREAD_CONVERSATIONS = '/:locale/conversations/box/unread'

// Admin.
export const ADMIN = '/admin'
export const PROJECTS_ADMIN = '/admin/projects/:project?locale=:locale'

// Selection.
export const SELECTION_PAGE = '/:locale/selections/:selection'

// Externals.
export const FACEBOOK = 'https://www.facebook.com/kisskissbankbank'
export const TWITTER = 'https://twitter.com/intent/user?screen_name=kissbankers'
export const INSTAGRAM = 'https://www.instagram.com/kisskissbankbank/'
export const LINKEDIN = 'https://www.linkedin.com/company/kisskissbankbank/'
export const YOUTUBE = 'https://www.youtube.com/user/KissKissCamp'
export const BLOG = 'https://blog.kisskissbankbank.com/'
export const MAISON_DE_CROWDFUNDING = 'https://www.maisondecrowdfunding.com'
export const CROWDFUNDING_IN_FRANCE =
  'https://welcome.kisskissbankbank.com/crowdfunding-en-france/'
export const BRANDING_GUIDELINES =
  'https://projets.kisskissbankbank.com/le-kit-com/'
export const PARTNER_DEALS =
  'https://projets.kisskissbankbank.com/boite-a-outils/'
export const JOBS = 'https://kisskissbankbank.welcomekit.co'
export const LENDOPOLIS = 'https://www.lendopolis.com'
export const GOODEED = 'https://www.goodeed.com'
export const MICRODON = 'https://www.microdon.org'
export const YOUMATTER = 'https://youmatter.world/'
export const SERVICES_TYPEFORM =
  'https://user-test.typeform.com/to/TZbJl14f?typeform-source=www.kisskissbankbank.com'
export const SERVICES_LIVE_MENTOR =
  'https://www.livementor.com/kisskissbankbank/?utm_source=kkbb-pagepresta&utm_medium=cta-ensavoirplus&utm_campaign=livementor-pageprestations-2023'
export const GOODANDTHEBRANDS = 'https://www.goodandthebrands.com/'
export const BIOTYFOOD_PROJECT =
  'https://www.kisskissbankbank.com/projects/biotyfood-le-sous-vide-nouvelle-generation'
export const WIA_PROJECT =
  'https://www.kisskissbankbank.com/projects/wia-mascara-naturel-reutilisable'
export const BLAST_BTS_LINK = 'https://www.instagram.com/p/CpQSR4RInCM/'
export const NEWSLETTERS = 'https://newsletter.kisskissbankbank.com/'

const routesByName = {
  MAKER_BARE_PROJECT,
  FUND_MY_PROJECT,
  FUND_MY_PROJECT_DONATION,
  FUND_MY_PROJECT_PRESALE,
  FUND_MY_PROJECT_REWARD,
  FUND_MY_PROJECT_PERMANENT,
  CAMPAIGN_TIPS_PREPARE_YOUR_COLLECT,
  CAMPAIGN_TIPS_COMMUNICATION,
  CAMPAIGN_TIPS_SEND_REWARDS,
  FUND_MY_PROJECT_CAMPAIGN,
  FUND_MY_PROJECT_PRE_ORDER,
  FUND_MY_PROJECT_SUBSCRIPTION,
}

export const getRouteFor = createSelector(
  (state) => state.locale || 'fr',
  (locale) =>
    (route, params = {}) => {
      return RoutingHelper.formatWithParams(route, { locale, ...params })
    },
)

export const getRouteByName = createSelector(
  (state) => state.locale || 'fr',
  (locale) =>
    (route, params = {}) =>
      RoutingHelper.formatWithParams(routesByName[route], {
        locale: locale,
        ...params,
      }),
)
