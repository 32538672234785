import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'

import {
  getProjectState,
  getLastStateAt,
  getDeletedAt,
  getSuspendedAt,
  getEndAt,
  getLanguages,
  getProjectSlug,
  getIsProjectMigrated,
  getMigratedAt,
} from 'kiss/modules/project-page/page-state/selectors'
import { getLocale } from 'kiss/app/redux'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'

import { Alert, ButtonGroup, Text, FlexWrapper } from '@kisskissbankbank/kitten'
import PreviewLink from './preview-link'

const Flag = ({ lang }) => {
  switch (lang) {
    case 'fr':
      return (
        <span className="k-u-size-small" aria-hidden>
          🇫🇷
        </span>
      )
    case 'en':
      return (
        <span className="k-u-size-small" aria-hidden>
          🇬🇧
        </span>
      )
    case 'nl':
      return (
        <span className="k-u-size-small" aria-hidden>
          🇳🇱
        </span>
      )
    default:
      return null
  }
}

const StateAndLanguages = () => {
  const t = useTranslation()

  const state = useSelector(getProjectState)
  const lastStateAt = useSelector(getLastStateAt)
  const deletedAt = useSelector(getDeletedAt)
  const suspendedAt = useSelector(getSuspendedAt)
  const endAt = useSelector(getEndAt)
  const locale = useSelector(getLocale)
  const languages = useSelector(getLanguages)
  const slug = useSelector(getProjectSlug)

  const isMigrated = useSelector(getIsProjectMigrated)
  const migratedAt = useSelector(getMigratedAt)

  const routeFor = useSelector(getRouteFor)

  const deletedAtText = (
    <Text weight="600" size="medium" color="font1">
      {` - `}
      {t('projects.admin_panel.deleted_project', {
        date: isoDateToLocaleFormat(deletedAt, {
          locale,
          format: 'dd MMMM yyyy',
        }),
        parseHtml: true,
      })}
    </Text>
  )

  const getText = () => {
    if (suspendedAt) return t('projects.admin_panel.suspended_project')
    else
      return (
        <>
          <Text> {t(`project.states.${state}`, { parseHtml: true })}</Text>{' '}
          <Text
            size="small"
            weight="400"
            cssColor="var(--color-grey-700)"
            fontStyle="italic"
          >
            {`(${t('projects.owner_panel.date_and_time', {
              date: isoDateToLocaleFormat(lastStateAt, {
                locale,
                format: 'dd MMMM yyyy',
              }),
              time: isoDateToLocaleFormat(lastStateAt, {
                locale,
                format: 'HH:mm',
              }),
            })})`}
          </Text>{' '}
          {isMigrated && (
            <>
              <Text>{t('projects.owner_panel.marked_migrated')}</Text>
              <Text
                size="small"
                weight="400"
                cssColor="var(--color-grey-700)"
                fontStyle="italic"
              >
                {`(${t('projects.owner_panel.date_and_time', {
                  date: isoDateToLocaleFormat(migratedAt, {
                    locale,
                    format: 'dd MMMM yyyy',
                  }),
                  time: isoDateToLocaleFormat(migratedAt, {
                    locale,
                    format: 'HH:mm',
                  }),
                })})`}
              </Text>
            </>
          )}
        </>
      )
  }
  return (
    <FlexWrapper gap={15}>
      <div>
        <Text weight="600" size="medium" color="font1">
          {t('projects.owner_panel.state', { parseHtml: true })}
        </Text>
        <Text weight="400" size="medium" color="font1">
          {getText()}
          {deletedAt && deletedAtText}
        </Text>
      </div>

      {endAt && (
        <div>
          <Text weight="600" size="medium" color="font1">
            {`${t('projects.owner_panel.end_at', { parseHtml: true })} `}
            <Text weight="400">
              {t('projects.owner_panel.date', {
                date: isoDateToLocaleFormat(new Date(endAt * 1000), {
                  locale,
                  format: 'dd MMMM yyyy',
                }),
              })}
            </Text>
          </Text>
        </div>
      )}

      <div className="k-u-flex@s-up k-u-flex-direction-row k-u-flex-alignItems-center k-u-flex-direction-column@s-down">
        <Text
          weight="600"
          size="medium"
          color="font1"
          className="k-u-margin-right-single"
        >
          {`${t('projects.owner_panel.preview', { parseHtml: true })} `}
        </Text>

        <ButtonGroup className="k-u-margin-top-single@s-down k-u-flex-justifyContent-start@s-down">
          {languages.map((language) => {
            const isSelected = language === locale
            const href = routeFor(PROJECT_ROUTE, {
              locale: language,
              project: slug,
            })

            return (
              <ButtonGroup.Button
                active={isSelected}
                type="button"
                tag="a"
                href={href}
                key={language}
                size="micro"
              >
                <div className="k-u-flex k-u-flex-alignItems-center">
                  <Flag lang={language} />

                  <span className="k-u-margin-left-single">
                    {t(`layouts.footer.lang.${language}`)}
                  </span>
                </div>
              </ButtonGroup.Button>
            )
          })}
        </ButtonGroup>
      </div>
      <PreviewLink />
      {isMigrated && (
        <Alert status="warning" className="k-u-margin-none">
          {t('projects.owner_panel.migration_alert', { parseHtml: true })}
        </Alert>
      )}
    </FlexWrapper>
  )
}

export default StateAndLanguages
