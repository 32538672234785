import React from 'react'
import { Helmet } from 'react-helmet'

const PlausibleTag = () => {
  return (
    <>
      <Helmet>
        <script
          defer
          data-domain="kisskissbankbank.com"
          src="https://plausible.io/js/script.js"
        >
        </script>
      </Helmet>
    </>
  )
}

export default PlausibleTag