import { DEFAULT_COLORS, DELIVERY_COUNTRIES } from 'kiss/app/constants'
import { getLocale } from 'kiss/app/redux'
import config from 'kiss/config'
import {
  getRouteFor,
  VERCEL_ORGANIZATION_MANAGER_NEWS,
  VERCEL_ORGANIZATION_MANAGER_NEWS_CREATE,
  VERCEL_ORGANIZATION_MANAGER_PAGE,
  VERCEL_PROJECT_MANAGER_NEWS,
  VERCEL_PROJECT_MANAGER_NEWS_CREATE,
  VERCEL_PROJECT_MANAGER_PAGE,
} from 'kiss/routes/redux'
import { getCurrentUserSlug, isMentor } from 'kiss/session/redux'
import { isFinished } from 'kiss/utils/project/state'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import forEach from 'lodash/fp/forEach'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import maxBy from 'lodash/fp/maxBy'
import orderBy from 'lodash/fp/orderBy'
import lodashProp from 'lodash/fp/prop'
import reduce from 'lodash/fp/reduce'
import sort from 'lodash/fp/sortBy'
import uniqBy from 'lodash/fp/uniqBy'
import values from 'lodash/fp/values'
import last from 'lodash/fp/last'
import map from 'lodash/fp/map'
import { createSelector } from '@reduxjs/toolkit'
import { initialState, MENTORS_LIMIT, NAME } from './redux'

const LANG = {
  FR: 'fr',
  EN: 'en',
  NL: 'nl',
}

const FALLBACKS = {
  [LANG.EN]: [LANG.EN, LANG.FR],
  [LANG.FR]: [LANG.FR, LANG.EN],
  [LANG.NL]: [LANG.EN, LANG.FR],
}

const HIDDEN_CHALLENGE = 'conseillers-lbp'

export const getProject = (state) => state?.[NAME]

export const getProjectUuid = (state) => state?.[NAME]?.uuid

export const getProjectId = (state) => state?.[NAME]?.id

export const getProjectSlug = (state) => state?.[NAME]?.slug

export const needToShowKYCModal = (project) =>
  getOr(false)(`${NAME}.kycWarning`)(project)
export const getFacebookPixelRef = (state) =>
  getOr(null)(`${NAME}.facebookPixelRef`)(state)
export const getEncryptedMetaConversionsApiAccessToken = (state) =>
  getOr(null)(`${NAME}.encryptedMetaConversionsApiAccessToken`)(state)
export const getProjectTags = (state) =>
  getOr([])(`${NAME}.taggedNewsCount`)(state)
export const getProjectState = (state) => state[NAME].state
export const getProjectDeletedAt = (state) => state[NAME].deletedAt
export const getProjectName = (state) => state[NAME].name

export const isSubscribersCountHidden = (state) =>
  state?.[NAME]?.hideSubscribersCount

export const getProjectCategoriesName = (state) =>
  state[NAME].categories?.map((c) => c.name)?.join(', ')
export const getProjectCity = (state) => state[NAME].city

export const getProjectImageUrl = (state) => state?.[NAME]?.image?.url

export const getProjectImageCropped = (state) =>
  state?.[NAME]?.image?.croppedUrl

export const getProjectImageMp4 = (state) => state?.[NAME]?.image?.mp4Url

export const getProjectImageWebm = (state) => state?.[NAME]?.image?.webmUrl

export const explicitContentType = (state) => state?.[NAME]?.explicitContent

export const getProjectVideo = (state) => state?.[NAME]?.video?.embed_tag

export const getIsProjectMigrated = (state) => state?.[NAME]?.isMigrated

const getTranslations = (state) => getOr({})(`${NAME}.translations`)(state)

export const getPayouts = (state) => getOr([])(`${NAME}.payouts`)(state)
export const getAcceptedPaymentProviders = (state) =>
  getOr([])(`${NAME}.acceptedPaymentProviders`)(state)
export const getProcessedAt = (state) =>
  getOr(null)(`${NAME}.processedAt`)(state)

const getProjectLocales = createSelector([getTranslations], (translations) =>
  translations.map(({ locale }) => locale),
)

const getCurrentTranslation = createSelector(
  [getTranslations, getLocale, getProjectLocales],
  (translations, locale, projectLocales) => {
    if (projectLocales.includes(locale)) {
      return find({ locale })(translations)
    }
    let fallbackTranslation = null
    for (const fallbackLocale of FALLBACKS[locale]) {
      const translationForFallbackLocale = find({ locale: fallbackLocale })(
        translations,
      )
      if (translationForFallbackLocale) {
        fallbackTranslation = translationForFallbackLocale
        break
      }
    }
    return fallbackTranslation
  },
)

export const getVideoTag = createSelector(
  [getProjectVideo, getCurrentTranslation],
  (oldVideoTag, currentTranslation) => {
    const translatedVideo = getOr('')('videoEmbedTag')(currentTranslation)
    return !isEmpty(translatedVideo) ? translatedVideo : oldVideoTag
  },
)

export const getPricingPlan = createSelector(
  (state) => state[NAME]?.pricingPlan,
  (pricingPlan) => pricingPlan || {},
)

export const isHandleServiceFee = (state) =>
  getOr(false)(`${NAME}.handleServiceFee`)(state)

// organization
export const getOrganization = (state) =>
  getOr(null)(`${NAME}.organization`)(state)
const getOrganizationName = (state) =>
  getOr(null)(`${NAME}.organization.name`)(state)

export const getOrganizationSlug = (state) =>
  getOr(null)(`${NAME}.organization.slug`)(state)
const getOrganizationAvatarNormalUrl = (state) =>
  getOr(null)(`${NAME}.organization.avatarImage.normalUrl`)(state)
export const getOrganizationMembers = (state) =>
  getOr(null)(`${NAME}.organization.members.edges`)(state)
export const getOrganizationContact = (state) =>
  getOr(null)(`${NAME}.organization.contact`)(state)
const getOrganizationContactSlug = (state) =>
  getOr(null)(`${NAME}.organization.contact.slug`)(state)

const getOrganizationPublishedProjects = (state) =>
  getOr([])(`${NAME}.organization.publishedProjects.edges`)(state)

const getOrganizationPublishedProjectsFormatted = (state) => {
  return flow(
    getOrganizationPublishedProjects,
    map(({ node }) => node),
  )(state)
}
export const getOrganizationProjectWithMoreSubscribers = (state) => {
  if (!getOrganization(state)) return []

  return flow(
    getOrganizationPublishedProjectsFormatted,
    filter((project) => project.state === 'started' && project.isPermanent),
    maxBy(
      (permanentProject) =>
        permanentProject?.subscriptionsConnection?.totalCount,
    ),
  )(state)
}

// owner

export const getOwnerAvatarNormalUrl = (state) =>
  state?.[NAME]?.owner?.image?.normalUrl

export const getOwner = (state) => state?.[NAME]?.owner

export const getOwnerSlug = (state) => state?.[NAME]?.owner?.slug

export const getOwnerName = (state) => state?.[NAME]?.owner?.username

// organization or owner
export const getOrganizationOrOwnerName = (state) =>
  getOrganizationName(state) || getOwnerName(state)

export const getOrganizationOrOwnerAvatarNormalUrl = (state) =>
  getOrganizationAvatarNormalUrl(state) || getOwnerAvatarNormalUrl(state)

// owner or contact
export const getOwnerOrContactSlug = (state) =>
  getOwnerSlug(state) || getOrganizationContactSlug(state)

export const getPageColors = createSelector(
  (state) => state[NAME]?.pageColors || {},
  (colors) => ({
    '--color-primary-100': colors.primary_100 || DEFAULT_COLORS.primary100,
    '--color-primary-300': colors.primary_300 || DEFAULT_COLORS.primary300,
    '--color-primary-500': colors.primary_500 || DEFAULT_COLORS.primary500,
    '--color-primary-700': colors.primary_700 || DEFAULT_COLORS.primary700,
    '--color-primary-900': colors.primary_900 || DEFAULT_COLORS.primary900,
  }),
)

export const hasFeature = (state) => (featureLabel) => {
  return getOr([])(`${NAME}.features`)(state)?.includes(featureLabel)
}

export const getFundedAmount = (state) =>
  (state?.[NAME]?.collectedAmount?.cents || 0) / 100

export const getCollectedCount = (state) => state?.[NAME]?.collectedCount

export const getProgressPercentage = (state) => state?.[NAME]?.fundingPercent

export const getAmount = (state) =>
  (state?.[NAME]?.goalAmount?.cents || 0) / 100

export const getCurrency = (state) => state?.[NAME]?.goalAmount?.currency

export const getGoalCount = (state) => state?.[NAME]?.goalCount

export const getGoalType = (state) => state?.[NAME]?.goalType

export const isCountGoalType = (state) => state?.[NAME]?.goalType === 'count'

export const hasGoal = (state) =>
  getOr(false)(`${NAME}.showPermanentGoal`)(state)

export const getGoalAmountDescription = createSelector(
  [getTranslations, getLocale, getCurrentTranslation],
  (translations, locale, currentTranslation) => {
    return getOr('')('goalAmountDescription')(currentTranslation)
  },
)
export const getGoalCountDescription = createSelector(
  [getTranslations, getLocale, getCurrentTranslation],
  (translations, locale, currentTranslation) => {
    return getOr('')('goalCountDescription')(currentTranslation)
  },
)

export const getEndAt = (state) => state?.[NAME]?.endAt

export const getDeletedAt = (state) => state?.[NAME]?.deletedAt

export const getLastStateAt = (state) => state?.[NAME]?.lastStateAt

export const getSuspendedAt = (state) => state?.[NAME]?.suspendedAt

export const getMigratedAt = (state) => state?.[NAME]?.migratedAt

export const getDuration = (state) => state?.[NAME]?.duration

export const getShortDesc = (state) => state?.[NAME]?.shortDesc

export const getFacebookUrl = (state) => state?.[NAME]?.facebookUrl

export const getTwitterUrl = (state) => state?.[NAME]?.twitterUrl

export const getInstagramUrl = (state) => state?.[NAME]?.instagramUrl

export const getWebsiteUrl = (state) => state?.[NAME]?.websiteUrl

export const getYoutubeUrl = (state) => state?.[NAME]?.youtubeUrl

export const isAggressivelyCached = (state) =>
  getOr(false)(`${NAME}.aggressivelyCached`)(state)
export const getNavItems = (state) =>
  getOr(initialState.navItems)(`${NAME}.navItems`)(state)
export const getMentors = (state) => getOr([])(`${NAME}.mentors`)(state)

const getMentorsSize = createSelector([getMentors], (mentors) => mentors.length)

export const hasMentors = createSelector(
  [getMentors],
  (mentors) => mentors.length > 0,
)

export const getEngagements = (state) => state[NAME]?.engagements || []

export const hasEngagements = createSelector(
  [getEngagements],
  (engagements) => engagements.length > 0,
)

export const getProjectUrl = (state) => state?.[NAME]?.publicUrl

export const isCurrentUserOwner = (state) => state?.[NAME]?.currentUser?.isOwner

export const isCurrentUserManager = (state) =>
  state?.[NAME]?.currentUser?.isManager

export const getCurrentUserPermissions = (state) =>
  getOr({})(`${NAME}.currentUserPermissions`)(state)

export const canCurrentUserViewPreview = (state) =>
  state?.[NAME]?.currentUserPermissions?.canViewPreview

export const canCurrentUserUpdate = (state) =>
  state?.[NAME]?.currentUserPermissions?.canUpdate

export const isDraftPageSharingEnabled = (state) =>
  state?.[NAME]?.draftPageSharingEnabled

export const getDraftPageToken = (state) => state?.[NAME]?.draftPageToken

const rewardSort = orderBy(['exclusive', 'starred', 'amount', 'label', 'uuid'])(
  ['desc', 'desc', 'asc', 'asc', 'asc'],
)
const rewardsSortByPosition = orderBy(['position'])(['asc'])
const rewardsCanBeSorted = (state) =>
  getOr(false)(`${NAME}.canSortRewards`)(state)

export const getProjectRewards = (state) => {
  const sortedRewards = rewardsCanBeSorted(state)
    ? rewardsSortByPosition
    : rewardSort

  return flow(
    lodashProp(`${NAME}.rewards`),
    uniqBy('uuid'),
    sortedRewards,
  )(state)
}
export const getAvailableRewards = createSelector(
  [getProjectRewards],
  (rewards) => rewards.filter((reward) => !reward.soldOut),
)
export const getSoldOutRewards = createSelector(
  [getProjectRewards],
  (rewards) => rewards.filter((reward) => reward.soldOut),
)

export const getDescription = (state) => state?.[NAME]?.description

export const getDescriptionFunding = (state) =>
  state?.[NAME]?.descriptionFunding

export const getTeamDescription = (state) => state?.[NAME]?.teamDescription

export const getNews = createSelector(
  (state) => state[NAME]?.newsConnection?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getNewsCount = (state) =>
  getOr(0)(`${NAME}.newsConnection.totalCount`)(state)

export const getFaqs = createSelector(
  (state) => state?.[NAME]?.faqs || [],
  (faqs) => {
    return faqs
      .slice()
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  },
)

const getOrdersEdges = (state) =>
  getOr([])(`${NAME}.ordersConnection.edges`)(state)

export const getOrders = (state) =>
  flow(
    getOrdersEdges,
    map((edge) => edge.node),
  )(state)

export const getTotalOrders = (state) =>
  getOr(0)(`${NAME}.ordersConnection.totalCount`)(state)

export const getLastProjectOrderCursor = (state) =>
  flow(getOrdersEdges, last, getOr(null)('cursor'))(state)

export const getCommentsTotalCount = (state) =>
  state?.[NAME]?.commentsConnection?.totalCount

export const currentUserHasBacked = (state) =>
  state?.[NAME]?.currentUser?.hasBacked ?? false

export const currentUserIsBacking = (state) => {
  return flow(
    getProjectRewards,
    find((reward) => reward?.currentUser?.isBacking),
  )(state)
}

export const getCurrentProjectRouteFor = (state) => (route, params) =>
  getRouteFor(state)(route, {
    project: state?.[NAME]?.slug,
    ...params,
  })

const getManagerRouteFor = createSelector(
  [getOrganizationSlug, getProjectSlug, getRouteFor],
  (organizationSlug, projectSlug, routeFor) =>
    (projectRoute, organizationRoute) => {
      const managerRoute = organizationSlug
        ? routeFor(organizationRoute, {
            organization: organizationSlug,
            project: projectSlug,
          })
        : routeFor(projectRoute, { project: projectSlug })
      return `${config[APP_ENV].manager.host}${managerRoute}`
    },
)

export const getManagerNewsRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_NEWS,
      VERCEL_ORGANIZATION_MANAGER_NEWS,
    ),
)

export const getManagerNewsCreateRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_NEWS_CREATE,
      VERCEL_ORGANIZATION_MANAGER_NEWS_CREATE,
    ),
)

export const getManagerPageRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_PAGE,
      VERCEL_ORGANIZATION_MANAGER_PAGE,
    ),
)

export const isProjectDonationEnabled = (state) =>
  state?.[NAME]?.donationEnabled

export const isProjectRecurringDonationEnabled = (state) =>
  state?.[NAME]?.recurringDonation

export const isProjectOnlyRecurringDonation = (state) =>
  state?.[NAME]?.onlyRecurringDonation

export const isFiscalReceiptsEnabled = (state) =>
  state?.[NAME]?.fiscalReceiptsEnabled

export const isPermanent = (state) => getOr(false)(`${NAME}.isPermanent`)(state)

export const isLogged = (state) => !!state?.[NAME]?.currentUser

export const getCartDonationAmount = (state) =>
  (state?.[NAME]?.cart?.donation?.cents ?? 0) / 100 || ''

export const getShippingAddressCountryCode = (state) =>
  state?.[NAME]?.cart?.shippingAddress?.countryCode

const isProjectMentor = (state) =>
  find((n) => n.slug === getCurrentUserSlug(state), getMentors(state))

export const needToShowMentorModal = createSelector(
  [isMentor, isProjectMentor, getMentorsSize],
  (isMentor, isProjectMentor, mentorSize) =>
    isMentor && !isProjectMentor && mentorSize < MENTORS_LIMIT,
)

export const isContributingActionDisabled = (state) =>
  isFinished(state[NAME].state)

const isProjectSuspended = (state) => !!getSuspendedAt(state)

export const isDonationDisabled = (state) => {
  if (isProjectSuspended(state)) return true

  return isProjectDonationEnabled(state) && isContributingActionDisabled(state)
}

export const isRewardDisabled = (state) => (reward) => {
  if (isProjectSuspended(state)) return true
  if (isContributingActionDisabled(state)) return true

  return isRewardUnavailable(state)(reward)
}

export const isRewardUnavailable = () => (reward) => {
  if (Number.isInteger(reward.remaining) && reward.remaining <= 0) return true
  if (reward.hasVariationSelection) {
    if (reward.soldOut) return true

    const totalQuantities = reward.variations.map(
      (variation) => variation.remainingQuantity,
    )

    if (totalQuantities.includes(null)) return false

    const totalRemainingQuantity = totalQuantities.reduce(
      (acc, quantity) => acc + quantity,
      0,
    )

    return totalRemainingQuantity <= 0
  }

  if (reward.variations[0].remainingQuantity == null) {
    return false
  }

  return reward.variations[0].remainingQuantity <= 0
}

export const hasVariations = (state) =>
  (state?.[NAME]?.cart?.variationsCount || 0) > 0

export const getVariationsCount = createSelector(
  (state) => state?.[NAME]?.cart?.variationsCount,
  (variationsCount) => variationsCount || [],
)

export const getCartDeliveryAmount = createSelector(
  (state) => state[NAME]?.cart?.deliveryAmount,
  (deliveryAmount) => deliveryAmount || {},
)

export const getRewardsHaloStatus = (state) =>
  state?.[NAME]?.rewardsHaloStatus || false

export const isPrelaunched = (state) => state?.[NAME]?.isPrelaunched || false

export const getChallenges = createSelector(
  (state) => state[NAME]?.challenges || [],
  (challenges) =>
    challenges.filter((challenge) => challenge.slug !== HIDDEN_CHALLENGE),
)

export const hasChallenges = createSelector(
  [getChallenges],
  (challenges) => challenges.length > 0,
)

export const getLanguages = (state) => state?.[NAME]?.languages || []

export const getProjectExtratimeState = (state) =>
  state?.[NAME]?.inExtratime || false

export const isProjectExtratimeForced = (state) => {
  const extratimeState = getOr(null)(`${NAME}.extratimeState`)(state)
  return extratimeState === 'forced'
}
export const getCurrentExtratime = (state) =>
  flow(
    get('fundingCampaigns'),
    filter(({ deletedAt }) => !deletedAt),
    find({ label: 'extratime' }),
  )(state[NAME])
export const getCurrentCampaign = (state) =>
  flow(
    get('fundingCampaigns'),
    filter(({ deletedAt }) => !deletedAt),
    find({ label: 'main' }),
  )(state[NAME])

export const isAdminPanelDataFetched = (state) =>
  state?.[NAME]?.adminPanelInitialDataFetched || false

export const isOwnerPanelDataFetched = (state) =>
  state?.[NAME]?.ownerPanelInitialDataFetched || false

export const isIndexable = (state) => getOr(true)(`${NAME}.isIndexable`)(state)
export const isOptionsEnabled = (state) =>
  getOr(false)(`${NAME}.optionsEnabled`)(state)

const getZoneCountries = (zone) => {
  const code = zone.zoneCode.toUpperCase()
  return DELIVERY_COUNTRIES[code] || [code]
}

export const getDeliveryCountryAmounts = (reward) => {
  const deliveryCountryAmounts = flow(
    sort((zone) => -zone.priority),
    reduce((amounts, zone) => {
      const countries = getZoneCountries(zone)

      forEach((code) => {
        amounts[code] = {
          amount: zone.amount,
          countryCode: code,
        }
      }, countries)

      return amounts
    }, {}),
    values,
  )(reward.deliveryZones)

  return deliveryCountryAmounts
}

export const getDeliveryCostCalculationMethod = (state) =>
  getOr('max')(`${NAME}.deliveryCostCalculationMethod`)(state)

export const hasFees = (deliveryCountryAmounts) => {
  return deliveryCountryAmounts.some(({ amount }) => amount.cents > 0)
}
