import { Button } from '@kisskissbankbank/kitten'
import { getRouteByName } from 'kiss/routes/redux'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

const RichContentButton = ({
  text,
  route = null,
  anchor = null,
  url = null,
  target = null,
  ...others
}) => {
  const routeByName = useSelector(getRouteByName)

  const href = (() => {
    if (route) {
      return routeByName(route)
    } else if (anchor) {
      return `#${anchor}`
    } else {
      return url
    }
  })()

  const handleClick = (e) => {
    if (!anchor) return

    const element = document.getElementById(anchor)
    if (!element) {
      window.location.href = anchor;
      return
    }

    e.preventDefault()
    scrollTo(element, 500)
  }

  return (
    <Button
      as="a"
      href={href}
      modifier="helium"
      size="medium"
      onClick={handleClick}
      target={target}
      rel={target === '_blank' ? 'noopener' : null}
      {...others}
    >
      {text}
    </Button>
  )
}

RichContentButton.propTypes = {
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
  anchor: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
}

export default RichContentButton
