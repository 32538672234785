import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PROJECT_WIDGET } from '../routes/redux'
import GoogleTagManager from '../utils/tracking/google-tag-manager'
import PlausibleTag from '../utils/tracking/plausible-tag'
import MatomoPageView from '../utils/tracking/matomo-page-view'
import FacebookPixelProvider from '../utils/tracking/facebook-pixel/provider'
import FacebookPixelTracker from '../utils/tracking/facebook-pixel/tracker'

const Metrics = () => {
  const isWidgetPage = useRouteMatch(PROJECT_WIDGET)
  if (APP_ENV !== 'production') return null
  if (isWidgetPage?.isExact) return null

  return (
    <>
      <GoogleTagManager />
      <PlausibleTag />
      <MatomoPageView />
      <FacebookPixelProvider>
        <FacebookPixelTracker eventName="info" />
      </FacebookPixelProvider>
    </>
  )
}

export default Metrics
