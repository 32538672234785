import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'

import { isFinished } from 'kiss/utils/project/state'
import {
  getProjectState,
  isContributingActionDisabled as isContributingActionDisabledSelector,
  isCountGoalType,
  isRewardDisabled,
  isRewardUnavailable as isRewardUnavailableSelector,
  getIsProjectMigrated,
} from 'kiss/modules/project-page/page-state/selectors'

import { RewardCard } from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'

const Button = ({ reward, buttonProps, displayButton }) => {
  const t = useTranslation()

  const projectState = useSelector(getProjectState)
  const shouldDisplayButton = displayButton && !isFinished(projectState)
  const isDisabled = useSelector(isRewardDisabled)(reward)

  const isContributingActionDisabled = useSelector(
    isContributingActionDisabledSelector,
  )

  const projectIsCountGoalType = useSelector(isCountGoalType)
  const isRewardUnavailable = useSelector(isRewardUnavailableSelector)(reward)

  const isMigrated = useSelector(getIsProjectMigrated)

  if (!shouldDisplayButton) return null

  const buttonLabel = (() => {
    switch (true) {
      case !!projectIsCountGoalType:
        return t('reward_card.count_goal_action')
      case !!isRewardUnavailable:
        return t('reward_card.run_out_action')
      default:
        return t('reward_card.action')
    }
  })()

  if (buttonProps.isLoading) {
    return (
      <RewardCard.Button>
        <LoadingAnimation color="var(--color-grey-000)" />
      </RewardCard.Button>
    )
  }

  return (
    <RewardCard.Button
      {...buttonProps}
      disabled={
        buttonProps?.disabled ||
        isContributingActionDisabled ||
        isDisabled ||
        isMigrated
      }
    >
      {buttonProps?.children || buttonLabel}
    </RewardCard.Button>
  )
}

export default Button
