import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useTranslation } from 'kiss/hooks/use-translation'
import { getAssetFor } from 'kiss/assets/redux'
import {
  getCurrentUser,
  getCurrentUserImage,
  logout,
  updateAuthenticityToken,
} from 'kiss/session/redux'
import { createToken } from './requests'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import * as qs from 'neoqs'

import {
  AvatarWithTextAndBadge,
  Button,
  HorizontalStroke,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { OrWrapper, TextSeparator } from '../external'

const Authenticated = () => {
  const t = useTranslation()

  const dispatch = useDispatch()

  const assetFor = useSelector(getAssetFor)
  const currentUserImage = useSelector(getCurrentUserImage)

  const currentUser = useSelector(getCurrentUser)

  const { firstName, lastName, email } = currentUser

  const { search } = useLocation()
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const { redirect_to, client_id } = params

  return (
    <div className="k-u-align-center">
      <img
        src={assetFor('illustrations/ulule/KissKiss_Ulule_figures.svg')}
        height={245}
      />
      <Title
        modifier="quaternary"
        tag="h3"
        className="k-u-margin-bottom-double"
      >
        {t('authenticate.kissconnect.connected.title')}
      </Title>
      <AvatarWithTextAndBadge
        style={{ justifyContent: 'center' }}
        className="k-u-margin-bottom-double"
      >
        <AvatarWithTextAndBadge.Image
          alt=""
          src={
            currentUserImage
              ? currentUserImage.url
              : assetFor('default_square.svg')
          }
        />

        <AvatarWithTextAndBadge.Text>
          <Text lineHeight="normal" weight="500">
            {`${firstName} ${lastName}`}
          </Text>
        </AvatarWithTextAndBadge.Text>
      </AvatarWithTextAndBadge>
      <Button
        type="submit"
        modifier="helium"
        fit="content"
        onClick={async () => {
          const response = await createToken({
            email,
            password: null,
            redirect_to,
            client_id,
          })

          dispatch(updateAuthenticityToken())

          const redirectUrl = new URL(redirect_to, window.location.origin)
          redirectUrl.searchParams.set('code', response?.data?.token)

          RoutingHelper.redirect(redirectUrl.toString())
        }}
      >
        {t('authenticate.kissconnect.continue')}
      </Button>
      <OrWrapper className="k-u-align-center k-u-margin-vertical-quadruple">
        <HorizontalStroke
          aria-hidden
          customSize={{ width: '100%', height: '1px' }}
          color="var(--color-grey-300)"
        />
        <TextSeparator
          size="micro"
          color="font2"
          weight="600"
          lineHeight="normal"
        >
          {t('authenticate.facebook_or_email.or')}
        </TextSeparator>
      </OrWrapper>
      <Button
        type="submit"
        modifier="hydrogen"
        fit="content"
        onClick={() => dispatch(logout()).then(() => window.location.reload())}
      >
        {t('authenticate.kissconnect.logout')}
      </Button>
    </div>
  )
}

export default Authenticated
