import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import loadable from '@loadable/component'

import { getSessionByQuery } from 'kiss/session/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { TranslationsProvider } from 'kiss/hooks/use-translation'
import Compose from 'kiss/utils/compose'
import { AlertsProvider } from 'kiss/app/alerts/context'
import { initSentry } from 'kiss/app/sentry'

import {
  AUTHENTICATE_ROUTE,
  BROWSING,
  BROWSING_BY_ENGAGEMENT,
  CAMPAIGN_TIPS_SUBPAGE,
  CONTRIBUTE,
  COOKIES,
  SELECTION_PAGE,
  FUND_MY_PROJECT,
  FUND_MY_PROJECT_SUBPAGE,
  FUND_MY_PROJECT_WITH_CHALLENGE,
  FUNNEL,
  FUNNEL_STEPS,
  FUNNEL_WITH_CHALLENGE,
  GIFT_CARD,
  GIFT_CARD_NEW,
  GIFT_CARD_SHOW,
  HOMEPAGE_ROUTE,
  KISS_CONNECT_SIGN_IN,
  LANDINGS_ROUTE,
  LANDINGS_WITH_FOLDER_ROUTE,
  MANIFESTO,
  MENTOR_PAGE,
  MENTOR_PAGE_EDITION,
  MENTOR_SIGNUP,
  MENTORS,
  MESSAGING,
  MESSAGING_ROOM,
  PAGES_ROUTE,
  PAGES_WITH_FOLDER_ROUTE,
  PAYMENT_FAILURE,
  PRICING_PLANS,
  PRISMIC_PREVIEW,
  PROJECT_ROUTE,
  PROJECT_SHARED_NEWS,
  PROJECT_UNSUBSCRIBE_NEWS,
  PROJECT_WIDGET,
  RESET_PASSWORD,
  RESTRICTED,
  PROJECT_DELETED,
  SEARCH,
  SERVICES,
  SIGN_IN,
  SIGN_UP,
  STATS,
  SUBSCRIBER_PROJECT_ABOUT_ROUTE,
  SUBSCRIBER_PROJECT_FAQ_ROUTE,
  SUBSCRIBER_PROJECT_NEWS_ROUTE,
  SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE,
  USER_KYC,
  USER_PROFILE,
  USER_SUBSCRIPTION_UPDATE_CARD_POLLING,
  ORGANIZATION_PROFILE_PUBLIC,
} from 'kiss/routes/redux'

import { HEADER_HEIGHT, pxToRem } from '@kisskissbankbank/kitten'

import Browsing from 'kiss/modules/browsing'
import ChangeCardPolling from 'kiss/modules/subscription-modal/components/change-card-polling'
import Contribute from 'kiss/modules/contribute'
import FacebookOrEmail from 'kiss/modules/authenticate/facebook-or-email'
import Failure from 'kiss/modules/contribute/common/failure'
import FundMyProject from 'kiss/modules/communication/fund-my-project/components/page'
import FundMyProjectSubpageLayout from 'kiss/modules/communication/fund-my-project'
import HomePage from 'kiss/modules/home'
import KissConnect from 'kiss/modules/authenticate/kiss-connect'
import MediaQueries from 'kiss/media-queries'
import Metrics from 'kiss/app/metrics'
import NotFound from 'kiss/modules/not-found'
import PermanentSubscriber from 'kiss/modules/project-page/permanent/subscriber'
import PrismicPage from 'kiss/modules/prismic-page'
import PrismicPreview from 'kiss/modules/communication/prismic-preview'
import ProjectDeleted from 'kiss/modules/project-deleted'
import ProjectPage from 'kiss/modules/project-page'
import PWAMeta from 'kiss/app/pwa'
import ResetPasswordForm from 'kiss/modules/authenticate/reset-password'
import Restricted from 'kiss/modules/restricted'
import Search from 'kiss/modules/search'
import SelectionPage from 'kiss/modules/selection-page'
import SharedNews from 'kiss/modules/project-page/permanent/common-pages/shared-news'
import SignIn from 'kiss/modules/authenticate/sign-in'
import SignUp from 'kiss/modules/authenticate/sign-up'
import UserKyc from 'kiss/modules/kyc'
import WidgetPageCard from 'kiss/modules/project-widget'
import StonlyProvider from 'kiss/utils/tracking/stonly/provider'
import UnsubscribeNews from 'kiss/modules/project-page/common/unsubscribe-news'

import 'kiss/app/web-worker'

import styled from 'styled-components'

const Funnel = loadable(
  () => import(/* webpackChunkName: 'funnel' */ 'kiss/modules/funnel'),
)

const Users = loadable(
  () => import(/* webpackChunkName: 'user-profile' */ 'kiss/modules/users'),
)

const MessagingPage = loadable(
  () => import(/* webpackChunkName: 'messaging' */ 'kiss/modules/messaging'),
)

const Organizations = loadable(
  () =>
    import(
      /* webpackChunkName: 'organizations' */ 'kiss/modules/organizations'
    ),
)

const PricingPlans = loadable(
  () =>
    import(/* webpackChunkName: 'pricing-plans'*/ 'kiss/modules/pricing-plans'),
)

const CampaignTipsLayout = loadable(
  () =>
    import(
      /* webpackChunkName: 'campaign-tips-layout' */ 'kiss/modules/communication/campaign-tips'
    ),
)

const CookiesPage = loadable(
  () => import(/* webpackChunkName: 'cookies-page' */ 'kiss/modules/cookies'),
)

const GiftCardLanding = loadable(
  () =>
    import(
      /* webpackChunkName: 'gift-card-landing'*/ 'kiss/modules/gift-card/landing'
    ),
)

const GiftCardNew = loadable(
  () =>
    import(/* webpackChunkName: 'gift-card-new'*/ 'kiss/modules/gift-card/new'),
)

const Manifesto = loadable(
  () => import(/* webpackChunkName: 'manifesto' */ 'kiss/modules/manifesto'),
)

const MentorPage = loadable(
  () =>
    import(/* webpackChunkName: 'mentor-page' */ 'kiss/modules/mentor-page'),
)

const Mentors = loadable(
  () => import(/* webpackChunkName: 'mentors' */ 'kiss/modules/mentors'),
)

const MentorSignUp = loadable(
  () =>
    import(
      /* webpackChunkName: 'mentor-signup' */ 'kiss/modules/users/mentor_signup'
    ),
)

const ServicePage = loadable(
  () =>
    import(/* webpackChunkName: 'service-page' */ 'kiss/modules/service-page'),
)

const StatsPage = loadable(
  () => import(/* webpackChunkName: 'stats-page' */ 'kiss/modules/stats-page'),
)

initSentry()

const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppRoutes = () => {
  return (
    <Switch>
      {/* Removes duplicate slashes */}
      <Route
        exact
        strict
        path="(.*//+.*)"
        render={({ location }) => (
          <Redirect to={location.pathname.replace(/\/\/+/g, '/')} />
        )}
      />
      <Route
        exact
        path={[BROWSING, BROWSING_BY_ENGAGEMENT]}
        component={Browsing}
      />
      <Route exact path={PAYMENT_FAILURE} component={Failure} />
      <Route path={CONTRIBUTE} component={Contribute} />

      <Route exact path={PROJECT_WIDGET} component={WidgetPageCard} />

      <Route
        exact
        path={[
          SUBSCRIBER_PROJECT_ABOUT_ROUTE,
          SUBSCRIBER_PROJECT_NEWS_ROUTE,
          SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE,
          SUBSCRIBER_PROJECT_FAQ_ROUTE,
        ]}
        component={PermanentSubscriber}
      />
      <Route path={PROJECT_UNSUBSCRIBE_NEWS}>
        <UnsubscribeNews />
      </Route>
      <Route path={PROJECT_SHARED_NEWS} component={SharedNews} />
      <Route path={PROJECT_ROUTE} component={ProjectPage} />

      <Route path={USER_SUBSCRIPTION_UPDATE_CARD_POLLING}>
        <ChangeCardPolling />
      </Route>

      <Route exact path={[FUNNEL, FUNNEL_WITH_CHALLENGE, FUNNEL_STEPS]}>
        <Funnel
          fallback={
            <LoaderWrapper>
              <LoadingAnimation />
            </LoaderWrapper>
          }
        />
      </Route>

      <Route exact path={SEARCH} component={Search} />
      <Route path={MENTORS}>
        <Mentors />
      </Route>
      <Route path={COOKIES} component={CookiesPage} />

      <Route exact path={[FUND_MY_PROJECT, FUND_MY_PROJECT_WITH_CHALLENGE]}>
        <FundMyProject />
      </Route>

      <Route exact path={FUND_MY_PROJECT_SUBPAGE}>
        <FundMyProjectSubpageLayout />
      </Route>

      <Route exact path={CAMPAIGN_TIPS_SUBPAGE}>
        <CampaignTipsLayout />
      </Route>

      <Route path={SERVICES} exact>
        <ServicePage />
      </Route>

      <Route exact path={PRISMIC_PREVIEW}>
        <PrismicPreview />
      </Route>

      <Route
        exact
        path={[
          PAGES_ROUTE,
          PAGES_WITH_FOLDER_ROUTE,
          LANDINGS_ROUTE,
          LANDINGS_WITH_FOLDER_ROUTE,
        ]}
        component={PrismicPage}
      />

      <Route exact path={HOMEPAGE_ROUTE} component={HomePage} />
      <Route exact path={STATS} component={StatsPage} />
      <Route exact path={MANIFESTO} component={Manifesto} />

      <Route path={USER_KYC}>
        <UserKyc />
      </Route>

      <Route path={USER_PROFILE}>
        <Users />
      </Route>

      <Route path={ORGANIZATION_PROFILE_PUBLIC}>
        <Organizations />
      </Route>

      <Route path={SELECTION_PAGE} component={SelectionPage} />

      {/* Authenticate */}
      <Route exact path={AUTHENTICATE_ROUTE} component={FacebookOrEmail} />
      <Route exact path={SIGN_IN} component={SignIn} />
      <Route exact path={SIGN_UP} component={SignUp} />
      <Route exact path={RESET_PASSWORD} component={ResetPasswordForm} />
      <Route path={KISS_CONNECT_SIGN_IN} component={KissConnect}/>

      <Route path={MENTOR_SIGNUP} exact>
        <MentorSignUp />
      </Route>
      <Route path={[MENTOR_PAGE, MENTOR_PAGE_EDITION]}>
        <MentorPage />
      </Route>

      <Route path={GIFT_CARD} exact>
        <GiftCardLanding />
      </Route>

      <Route path={[GIFT_CARD_NEW, GIFT_CARD_SHOW]} exact>
        <GiftCardNew />
      </Route>

      <Route path={[MESSAGING, MESSAGING_ROOM]} exact>
        <MessagingPage />
      </Route>

      <Route path={PRICING_PLANS} exact>
        <PricingPlans />
      </Route>

      <Route path={RESTRICTED}>
        <Restricted />
      </Route>

      <Route path={PROJECT_DELETED}>
        <ProjectDeleted />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export const App = ({ store }) => {
  useEffect(() => {
    getSessionByQuery()(store.dispatch, store.getState)
  })

  return (
    <Compose
      items={[
        [Provider, { store }],
        [TranslationsProvider],
        [AlertsProvider],
        [StonlyProvider],
      ]}
    >
      <PWAMeta />
      <MediaQueries />
      <Metrics />
      <AppRoutes />
    </Compose>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}
